import React, { Suspense, useContext } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import { UserContext } from "../context/userContext";
import ErrorPageNotFoundAlt from "../pages/error/PageNotFoundAlt";


// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/login/Login"));
const Otp = React.lazy(() => import("../pages/otp/Otp"))


//allocation
const CouponAllocation = React.lazy(() => import("../pages/coupon-allocation/CouponAllocation"))
const Reedem = React.lazy(() => import("../pages/reedem/Reedem"))

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<(props: any) => JSX.Element>;
  session?: any
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => {
  return <Suspense fallback={loading()}>
    <Component />
  </Suspense>
}

const AllRoutes = () => {

  const { checkLogIn } = useContext(UserContext)

  return useRoutes([
    {
      // public routes
      path: "/",
      children: [
        { path: "*", element: <ErrorPageNotFoundAlt /> },
        { path: "/", element: <Navigate to={"/login"} /> },
        { path: "/login", element: <LoadComponent component={Login} /> },
        { path: "/otp", element: <LoadComponent component={Otp} /> }

      ],
    },
    {
      // private routes
      path: "/auth",
      element: checkLogIn() ? <Outlet /> : (<Navigate to={"/login"} />),
      children: [
        { path: "*", element: <ErrorPageNotFoundAlt /> },
        { path: "/auth/allocation", element: <LoadComponent component={CouponAllocation} /> },
        { path: "/auth/reedem", element: <LoadComponent component={Reedem} /> }
        // { path: "/auth", element: <LoadComponent component={session.userType === UserType.EMPLOYEE ? Dashboard : session.userType === UserType.CUSTOMER ? CustomerDashboard : VendorDashboard} userType={[UserType.EMPLOYEE, UserType.CUSTOMER, UserType.VENDOR]} session={session} /> },
      ],
    },

  ]);
};

export { AllRoutes };
