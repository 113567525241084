import React from 'react';
import './App.css';
import { UserContext } from './context/userContext';
import Routes from './routes/Routes';

function App() {
  function checkLogIn() {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    return isLoggedIn ? true : false;
  }
  return (
    <div className="App">
      <UserContext.Provider value={{ checkLogIn }}>
        <Routes />
      </UserContext.Provider>
    </div>
  );
}

export default App;
